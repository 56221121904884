
const requestLoginRunType = "REQUEST_LOGIN_RUN";
const receiveLoginRunType = "RECEIVE_LOGIN_RUN";
const errorLoginRunType = "ERROR_LOGIN_RUN";
const clearErrorType = "CLEAR_ERROR";
const clearTokenType = "CLEAR_TOKEN";


const initialState = {
    token: sessionStorage.getItem("token") || null, errorText: null, isRunLoading: false
};

export const actionCreators = {
    clearError: () => (dispatch) => {
        dispatch({ type: clearErrorType });
    },
 

    clearToken: () => (dispatch) => {
         dispatch({ type: clearTokenType });
    },
    
    requestLoginRun: (username,password) => async (dispatch, getState) => {
        dispatch({ type: requestLoginRunType });

        const url = "api/auth/login";
        try {
            const response = await fetch(url,
                {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({username:username, password:password})
                }
            );
            const data = await response.json();
            sessionStorage.setItem("token", data.token);
            dispatch({ type: receiveLoginRunType, token: data.token });

        } catch (e) {
            dispatch({ type: errorLoginRunType, errorText: e });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === clearErrorType) {
        return {
            ...state,
            errorText: null
        };
    }
 

    if (action.type === requestLoginRunType) {
        return {
            ...state,
            isRunLoading: true,
            token: null,
            errorText: null
        };
    }

    if (action.type === clearTokenType) {
        return {
            ...state,
            token: null,
            isRunLoading: false
        };
    }

    if (action.type === receiveLoginRunType) {
        return {
            ...state,
            token: action.token,
            isRunLoading: false,
            errorText: null
        };
    }
    return state;
};
