import React, { Component } from "react";
import {  Button, FormControl, FormGroup, Clearfix } from "react-bootstrap";

export default class DateSelector extends Component {

    constructor(props) {
        super(props);
        this.daysInMonth = this.daysInMonth.bind(this);
        this.handleChangeDay = this.handleChangeDay.bind(this);
        this.handleChangeMonth = this.handleChangeMonth.bind(this);
        this.handleChangeYear = this.handleChangeYear.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setToday = this.setToday.bind(this);
    }

    state = {
        day: 1,
        month: 1,
        year: 2018,
        hour: 0,
        minute:0,
        maxDays: 31,
        value: new Date(),
        valueString: ""
    }

    componentDidMount() {
        this.setValue();
    }

    componentDidUpdate() {

    }

    reload() {
        window.location.reload(true);
    }

    setValue() {

        const date = new Date(this.state.year, this.state.month-1, this.state.day, this.state.hour, this.state.minute);
        this.setState({ value: date, valueString: date.toISOString() });
    }

    setToday() {
        const date = new Date();
        this.setState({
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
            hour: 0,
            minute: 0,
            maxDays: this.daysInMonth(date.getFullYear(), date.getMonth() + 1)
        }, () => this.setValue());
    }

   
    handleChangeDay = (e) => {
        e.preventDefault();
        this.setState({ day: e.target.value }, () => this.setValue());

    }
    handleChangeMonth = (e) => {
        e.preventDefault();
        const maxDays = this.daysInMonth(this.state.year, e.target.value);
        this.setState({ month: e.target.value, maxDays, day: this.state.day > maxDays ? 1 : this.state.day }, () => this.setValue());

    }

    handleChangeYear = (e) => {
        e.preventDefault();
        const maxDays = this.daysInMonth(e.target.value, this.state.month);
        this.setState({ year: e.target.value, day: this.state.day > maxDays ? 1 : this.state.day }, () => this.setValue());
    }


    handleChangeHour = (e) => {
        e.preventDefault();
        this.setState({ hour: e.target.value }, () => this.setValue());
    }

    handleChangeMinute = (e) => {
        e.preventDefault();
        this.setState({ minute: e.target.value }, () => this.setValue());
    }

 

    daysInMonth(year,month) {
        var ret = new Date(year, month, 0).getDate();
        return ret;
    }

    render() {
        return (
            <FormGroup>
                <FormControl value={this.state.day} title={this.state.valueString} componentClass="select" onChange={this.handleChangeDay} style={{ 'width': '60px', 'float': 'left' }} placeholder="Gün" inline="true">
                    {
                        [...Array(this.state.maxDays).keys()].map(n => <option value={n + 1} key={"d" + (n + 1)}> {n + 1}</option>)
                    }
                </FormControl>
                <FormControl value={this.state.month} componentClass="select" onChange={this.handleChangeMonth} style={{ 'width': '60px', 'float': 'left' }} placeholder="Ay" inline="true">
                    {
                        [...Array(12).keys()].map(n => <option value={n + 1} key={"d" + (n + 1)}>{n + 1}</option>)
                    }
                </FormControl>
                <FormControl value={this.state.year} componentClass="select" onChange={this.handleChangeYear} style={{ 'width': '70px', 'float': 'left' }} placeholder="Yıl" inline="true">
                    {
                        [...Array(100).keys()].map(n => <option value={n + 1930} key={"d" + (n + 1930)}>{n + 1930}</option>)
                    }
                </FormControl>

                <FormControl componentClass="select" onChange={this.handleChangeHour} style={{ 'width': '50px', 'float': 'left', marginLeft:'20px' }} placeholder="Yıl" inline="true">
                    {
                        [...Array(24).keys()].map(n => <option value={n} key={"d" + (n )}>{n }</option>)
                    }
                </FormControl>
                <div style={{ height: '34px', lineHeight: '34px', float: 'left', textAlign: 'center', width: '10px' }}>:</div>
                <FormControl componentClass="select" onChange={this.handleChangeMinute} style={{ 'width': '50px', 'float': 'left' }} placeholder="Yıl" inline="true">
                    {
                        [...Array(60).keys()].map(n => <option value={n} key={"d" + (n )}>{n }</option>)
                    }
                </FormControl>
                <small>
                    <Button bsStyle="link" bsSize="xsmall" onClick={this.setToday}>bugün</Button>
                </small>
                <input type='hidden' value={this.state.valueString} name={this.props.name} />
                <Clearfix />
            </FormGroup>
        );
    }
}

