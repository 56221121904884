import React, { Component } from "react";
import Select, { components } from "react-select";
import { store } from "../../../index";
import { Alert, Button } from "react-bootstrap";

export default class MallSelector extends Component {

    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        this.reload = this.reload.bind(this);
        this.renderError = this.renderError.bind(this);
        this.renderNormal = this.renderNormal.bind(this);
    }

    state = {
        selectedOption: null,
        isLoading: true,
        list: [],
        errorText: null,
        options: []
    }

    componentDidMount() {
        this.getList();
    }

    componentDidUpdate() {

    }

    reload() {
        window.location.reload(true);
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption }, () => this.props.onValueChanged ? this.props.onValueChanged(this.state.selectedOption) : {});

    }

    getList() {
        this.setState({ isLoading: true, list: [] });
        const url = `api/options/mall`;
        fetch(url, {
            method: "get",
            headers: {
                "Accept": "application/json",
                "token": store.getState().user.token
            }
        }).then(response => {
            if (response.status === 200) {
                response.json().then((result) => {
                    this.setState({
                        isLoading: false,
                        list: result.map((m) => ({ label: m.Name, value: m.Code })),
                        errorText: null
                    });
                }
                );
            }
            this.setState({ isLoading: false, list: [], errorText: "Hata! Liste alınamadı." });
        }).catch(() => {
            this.setState({ isLoading: false, list: [], errorText: "Hata! Liste alınamadı." });
        });
    }

    renderError() {
        return (
            <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
                <h4>AVM Listesi Çekilemedi!</h4>
                <p>
                    Listeyi çekmeyi tekrar deneyebilirsin veya sayfayı komple yeniden yükleyebilirsin.
                </p>
                <p>
                    <Button onClick={this.getList} bsStyle="danger">Yeniden Dene</Button>
                    <span> veya </span>
                    <Button onClick={this.reload}>Baştan yükle</Button>
                </p>
            </Alert>
        );
    }

    renderNormal() {

        const Option = (props) => {
            return (
                <div>
                    <components.Option {...props} />
                </div>
            );
        };

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "white" : provided.color,
                backgroundColor: state.isSelected ? "#815789" : provided.color
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = "opacity 300ms";

                return { ...provided, opacity, transition };
            }
        };

        const { selectedOption, list, isLoading } = this.state;
        return (
            <div>
                <Select
                    styles={customStyles}
                    value={selectedOption}
                    components={{ Option }}
                    options={list}
                    onChange={this.handleChange}
                    isLoading={isLoading}
                    isSearchable
                    placeholder={"AVM Seçiniz"}
                />
                <input type={"hidden"} name={this.props.name} value={selectedOption ? selectedOption.value : ""} />
            </div>
        );
    }

    render() {
        return (this.state.errorText ? this.renderError : this.renderNormal)();
    }
}

