
const requestDashboardRunType = "REQUEST_DASHBOARD_RUN";
const receiveDashboardRunType = "RECEIVE_DASHBOARD_RUN";
//const errorLoginRunType = 'ERROR_LOGIN_RUN';
//const clearErrorType = 'CLEAR_ERROR';
//const clearTokenType = 'CLEAR_TOKEN';


const initialState = {
    data : [], isRunLoading: false
};

export const actionCreators = {
    
    requestDashboardRun: () => async (dispatch, getState) => {
        dispatch({ type: requestDashboardRunType });
        const userStore = getState().user;

        const url = "api/dashboard/get";
        try {
            const response = await fetch(url,
                {
                    method: "get",
                    headers: {
                        "Accept": "application/json",
                        "token": userStore.token

                    }
                }
            );
            const data = await response.json();
            dispatch({ type: receiveDashboardRunType, data });

        } catch (e) {
          //  dispatch({ type: errorLoginRunType, errorText: e });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;


    if (action.type === requestDashboardRunType) {
        return {
            ...state,
            isRunLoading: true,
            data: []
        };
    }

  

    if (action.type === receiveDashboardRunType) {
        return {
            ...state,
            data: action.data,
            isRunLoading: false
        };
    }
    return state;
};
