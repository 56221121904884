import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "./store/User";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/pages/home/Home";
import Query from "./components/pages/query/Query";
import Login from "./components/pages/login/Login";
import Clients from "./components/pages/clients/Clients";

class App extends Component {

    renderLogin() {
        return <Login />;
    }
    renderNormal() {
        return (
            <Layout>
                <Route exact path="/" component={Home} />
                <Route exact path="/clients" component={Clients} />
                <Route path="/query/:queryName?" component={Query} />
            </Layout>
        );
    }
    render() {
        return (this.props.token ? this.renderNormal : this.renderLogin)();
    }
}
export default connect(
    state => state.user,
    dispatch => bindActionCreators(actionCreators, dispatch),null, {
        pure: false
    }
)(App);
