import _ from "lodash";
import moment from "moment";
const requestRefreshDataType = "REQUEST_REFRESH_DATA";
const receiveRefreshDataType = "RECEIVE_REFRESH_DATA";
const clearErrorType = "CLEAR_ERROR";

let controller = new AbortController();
let signal = controller.signal;

const initialState = {
    errorText: null,
    isRunLoading: true,
    googleMean: 2,
    clients: []
};

export const actionCreators = {
    clearError: () => (dispatch) => {
        dispatch({ type: clearErrorType });
    },

    requestRefreshData: () => (dispatch, getState) => {
        const userStore = getState().user;
        const url = `api/monitor/list`;

        controller.abort();
        controller = new AbortController();
        signal = controller.signal;

        dispatch({ type: requestRefreshDataType });

        var requestOptions = {
            method: "get",
            signal: signal,
            headers: {
                "Accept": "application/json",
                "token": userStore.token
            }
        };

        fetch(url, requestOptions).then(response => {
            return response.json();
        }).then(data => {
            dispatch({ type: receiveRefreshDataType, data });
        }).catch((e) => { });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === clearErrorType) {
        return {
            ...state,
            errorText: null
        };
    }

    if (action.type === requestRefreshDataType) {
        return {
            ...state,
            errorText: null,
            isRunLoading: true

        };
    }

    if (action.type === receiveRefreshDataType) {
        moment.locale("tr");
        return {
            ...state,
            errorText: null,
            isRunLoading: false,
            numberOfOnlineClients: 1,

            serverMean: Math.floor(dirtyMean(action.data.clients.map(x => dirtyMean(x.serverPingLogs.map(x => x.value))))),
            googleMean: Math.floor(dirtyMean(action.data.clients.map(x => dirtyMean(x.googlePingLogs.map(x => x.value))))),
            clients: action.data.clients.map(x =>
                Object.assign(x, {
                                    lastSeen: moment( _(x.serverPingLogs).maxBy(y=>y.time).time / 10000 - 11644473600000).fromNow(),
                                    serverMeanLevel: Math.ceil(_(x.serverPingLogs).takeRight(10).map(x => x.value).mean() / 100)
                                 }))
        };
    }

    return state;
};

function dirtyMean(arr) {
    let avg = 0.0;
    for (let i = 0; i < arr.length; i++) {
        avg += arr[i] / arr.length;
    }
    return avg;
}