import React, { Component } from "react";
import { Glyphicon } from "react-bootstrap";
import ClientDetailsModal from "./ClientDetailsModal";
import "./ClientRow.css";

export default class ClientRowTitle extends Component {

    constructor(props) {
        super(props);
        this.showDetails = this.showDetails.bind(this);
        //this.modal = this.modal.bind(this);
        //this.spark = this.spark.bind(this);
        this.handleHide = this.handleHide.bind(this);
    }

    state = {
        isLoading: true
    }

    componentDidMount() {
    }

    componentDidUpdate() {

    }
     
    handleHide() {
        this.setState({ showDetails: false });
    }

    showDetails() {
        this.setState({ showDetails: true });
    }

    render() {
        return (
            <div>
                <strong title={this.props.data.lastSeen}> {this.props.data.mallcode} </strong> ({this.props.data.username}) <Glyphicon glyph="cog" onClick={this.showDetails} /> 
                <ClientDetailsModal data={this.props.data}  show={this.state.showDetails} onHide={this.handleHide} />
                </div>
        );
    }
}

