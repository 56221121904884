import React, { Component } from "react";
import { Glyphicon, Grid, Panel, Row, Col } from "react-bootstrap";
import "./ClientsDashboard.css";
export default class ClientsDashboard extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        isLoading: true
    }

    componentDidMount() {
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.googleMean !== nextProps.googleMean
            || this.props.googleMean !== nextProps.googleMean
            || this.props.googleMean !== nextProps.googleMean
            || this.props.googleMean !== nextProps.googleMean);

    }
    componentDidUpdate() {
    }

    panel(title, value) {
        return (
            <Col md={3}>
                <Panel className={"ClientsDashboardPanel"}>
                    <Panel.Body>
                        <div className={"title"} >{title}</div>
                        <div className={"value"} >{value}</div>
                    </Panel.Body>
                </Panel>
            </Col>);
    }

    render() {
        return (
            <Grid fluid >
                <Row>
                    {this.panel("Bağlı İstemci Sayısı", "50")}
                    {this.panel("Bağlı AVM Sayısı", "50")}
                    {this.panel("Ortalama Sunucu Ping", "500ms")}
                    {this.panel("Ortalama Google Ping", this.props.googleMean + "ms")}
                </Row>
            </Grid>
        );
    }
}

