import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Glyphicon, NavItem } from "react-bootstrap";
import { actionCreators } from "../store/Query";

class QueryNavigation extends Component {
    constructor(props) {
        super(props);
        this.props.requestQueryList();
    }

    componentDidMount() {
    }

    render() {
        return this.props.list.map(query =>
            (
                <LinkContainer key={query.queryName} to={"/query/" + query.queryName}>
                    <NavItem>
                        <Glyphicon glyph={query.iconName} /> {query.displayName}
                    </NavItem>
                </LinkContainer>
            )
        );
    }
}

export default connect(
    state => state.query,
    dispatch => bindActionCreators(actionCreators, dispatch), null, {
        pure: false
    }
)(QueryNavigation);
