import React, { Component } from "react";
import { Alert, Button, Row, Col, Label, Glyphicon, Modal, Table, Tabs, Tab } from "react-bootstrap";
import Sparkline from '@rowno/sparkline';
import ClientRowTitle from "./ClientRowTitle";
import "./ClientRow.css";
import _ from "lodash";
export default class ClientRow extends Component {

    constructor(props) {
        super(props);
        this.showDetails = this.showDetails.bind(this);
        this.modal = this.modal.bind(this);
        this.spark = this.spark.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.cardColor = this.cardColor.bind(this);
        this.warn = this.warn.bind(this);
    }

    state = {
        isLoading: true
    }

    componentDidMount() {
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log(this.props.data.version + "   " + nextProps.data.version);
        return this.props.data.version !== nextProps.data.version;
    }
    componentDidUpdate() {

    }

    modal() {
        return (
            <Modal
                show={this.state.showDetails}
                onHide={this.handleHide}
                container={this}
                aria-labelledby="contained-modal-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">
                        Contained Modal
            </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Tab 1">
                            <Table striped condensed >
                                <tbody>
                                    <tr>
                                        <th>İşlemci:</th>
                                        <td>Intel I7 3212MHz</td>
                                    </tr>
                                    <tr>
                                        <th>Bellek:</th>
                                        <td>8GB</td>
                                    </tr>
                                    <tr>
                                        <th>İşletim Sistemi:</th>
                                        <td>Windows 8</td>
                                    </tr>
                                    <tr>
                                        <th>İşlemci:</th>
                                        <td>Intel I7 3212MHz</td>
                                    </tr>
                                    <tr>
                                        <th>Bellek:</th>
                                        <td>8GB</td>
                                    </tr>
                                    <tr>
                                        <th>İşletim Sistemi:</th>
                                        <td>Windows 8</td>
                                    </tr>
                                </tbody>
                            </Table>

                        </Tab>
                        <Tab eventKey={2} title="Tab 2">
                            Tab 2 content
                        </Tab>
                        <Tab eventKey={3} title="Tab 3" disabled>
                            Tab 3 content
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHide}>Close</Button>
                </Modal.Footer>
            </Modal>);
    }

    getHue(mean, sum, redThreshold) {

        const diff = mean - redThreshold;
        const diffRatio = 50 + 50 * diff / sum;
        return Math.floor(Math.floor(100 * diffRatio / mean) / 10) * 10;
    }

    cardColor() {
        const hue = 85 - _.ceil(_.min([this.props.data.serverMeanLevel, 30]) * 2.83333);
        return 'hsla(' + hue + ',80%, 40%, 0.9)';
    }

    spark(data, redThreshold) {
        data = _(data)
            .orderBy(x => x.time, "asc")
            .takeRight(10)
            .map(d => d.value)
            .value();
        const sum = data.reduce((x, y) => x + y);
        const mean = sum / data.length;
        const hue = this.getHue(mean, sum, redThreshold);
        const lines = [
            {
                values: data,
                colors: {
                    area: 'hsla(' + hue + ',80%, 60%, 0.4)',
                    line: 'hsla(' + hue + ',80%, 40%, 0.9)'
                }

            }
        ];
        console.table(lines);
        return (
            <Sparkline
                width={56}
                height={12}
                lines={lines}
            />
        );
    }
    handleHide() {
        this.setState({ showDetails: false });
    }
    showDetails() {
        this.setState({ showDetails: true });
    }

    warn() {
        const { serverMeanLevel } = this.props.data;

        if (serverMeanLevel < 5) {
            return <Label bsStyle="success">İdeal Bağlantı</Label>;
        }

        if (serverMeanLevel < 10) {
            return <Label bsStyle="info">İyi Bağlantı</Label>;
        }

        if (serverMeanLevel < 15) {
            return <Label bsStyle="warning">Zayıf Bağlantı</Label>;
        }
        if (serverMeanLevel < 25) {
            return <Label bsStyle="warning">Kötü Bağlantı</Label>;
        }
        return <Label bsStyle="danger">Çok Kötü Bağlantı</Label>;
    }

    render() {
        return (
            <Row className={"clientRow"} style={{ borderLeftColor: this.cardColor(), borderLeftWidth: 7 }}>
                <Col md={4}>
                    <ClientRowTitle data={this.props.data} />
                    <small>{this.warn()}</small>
                </Col>
                <Col md={2}>
                    {_.last(this.props.data.serverPingLogs).value}
                    <small className={"extra"}>ms</small>
                    <br />
                    {this.spark(this.props.data.serverPingLogs, 250)}

                </Col>
                <Col md={2}>
                    {_.last(this.props.data.googlePingLogs).value}
                    <small className={"extra"}>ms</small> <br />
                    {this.spark(this.props.data.googlePingLogs, 500)}
                </Col>

                <Col md={2}>
                    %{_.last(this.props.data.cpuLogs).value}
                    <br />
                    {this.spark(this.props.data.cpuLogs, 20)}
                </Col>

                <Col md={2}>
                    %{_.last(this.props.data.ramLogs).value}
                    <br />
                    {this.spark(this.props.data.ramLogs, 85)}
                </Col>
            </Row>
        );
    }
}

