import React, { Component } from "react";
import { Button, Modal, Table, Tabs, Tab } from "react-bootstrap";
import "./ClientRow.css";

export default class ClientDetailsModal extends Component {

    constructor(props) {
        super(props);
        this.handleHide = this.handleHide.bind(this);
    }

    handleHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.handleHide}
                container={this}
                aria-labelledby="contained-modal-title">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">
                        Contained Modal
            </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Tab 1">
                            <Table striped condensed >
                                <tbody>
                                    {
                                        this.props.data.info.map(x =>
                                            (
                                                <tr>
                                                    <th>{x.title}:</th>
                                                    <td>{x.value}</td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </Table>

                        </Tab>
                        <Tab eventKey={2} title="Tab 2">
                            Tab 2 content
                        </Tab>
                        <Tab eventKey={3} title="Tab 3" disabled>
                            Tab 3 content
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleHide}>Close</Button>
                </Modal.Footer>
            </Modal>);
    }


}

