import React, { Component } from "react";
import { Glyphicon, Grid, Panel, Row, Col } from "react-bootstrap";
import ClientRow from "../clientrow/ClientRow";
import _ from "lodash";
export default class ClientsDashboard extends Component {

    constructor(props) {
        super(props);
    }


    componentDidMount() {
    }

     

    componentDidUpdate() {

    }

    render() {
        return (
            <Grid fluid >
                <div className={"sticky"}>

                    <Row className={"clientRow header"}>
                        <Col md={4}>
                            İstemci
                        </Col>
                        <Col md={2}>
                            Sunucu Ping
                        </Col>
                        <Col md={2}>
                            Google Ping
                        </Col>
                        <Col md={2}>
                            CPU
                        </Col>

                        <Col md={2}>
                            RAM
                        </Col>
                    </Row>
                </div>
                {/*
                <div className="bad">Problemli</div>
                <div className="good">Problemsiz </div>
                {
                    [...(new Array(130))].map((x, i) => <ClientRow key={i} data={x}  />)
                }
                */}
               
                {

                    _(this.props.clients)
                        .orderBy(x => x.serverMeanLevel, "desc")
                        .map(x => <ClientRow key={x.key} data={x} />)
                        .value()
                }
            </Grid>
        );
    }
}

