import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/User";
import { Button, FormGroup, Form, Panel,  ControlLabel, FormControl } from "react-bootstrap";

class Login extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        const username = data.get("username");
        const password = data.get("password");
        this.props.requestLoginRun(username, password);
    };

    render() {
        return (
            <div className={"login-div"}>
                <Panel>
                    <Panel.Body>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup controlId={"loginformusername"}     >
                            <ControlLabel>Kullanıcı Adı</ControlLabel>
                            <FormControl type="text" name={"username"} />
                            <FormControl.Feedback />
                        </FormGroup>
                        <FormGroup controlId={"loginformpassword"}     >
                            <ControlLabel>Parola</ControlLabel>
                            <FormControl type="password" name={"password"} />
                            <FormControl.Feedback />
                        </FormGroup>
                        <FormGroup>
                            <Button bsStyle="primary" type="submit"  > {"Giriş Yap"}</Button>
                        </FormGroup>
                        </Form>
                        </Panel.Body>
                </Panel>
            </div>
        );
    }
}

export default connect(
    state => state.user,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);
