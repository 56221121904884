import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import * as Query from "./Query";
import * as User from "./User";
import * as Dashboard from "./Dashboard";
import * as Clients from "./Clients";


export default function configureStore(history, initialState) {
    const reducers = {
        query: Query.reducer,
        user: User.reducer,
        clients: Clients.reducer,
        dashboard: Dashboard.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === "development";
    if (isDevelopment && typeof window !== "undefined" && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
