import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/Dashboard";
import { connect } from "react-redux";
import { Grid, Row, Col, PageHeader, Panel } from "react-bootstrap";

class Home extends Component {
    constructor(props) {
        super(props);

        this.props.requestDashboardRun();

    }

    renderLoading() {

        return <span>Yükleniyor...</span>;
    }


    renderBoards(s) {
        return (
            <div>
                <Grid fluid>
                    <Row >
                        {s.props.data.map(d => {
                            return (
                                <Col xs={12} md={4} key={"dashboarditem-" + d.order}>
                                    <Panel bsStyle="primary">
                                        <Panel.Heading>{d.title}</Panel.Heading>
                                        <Panel.Body className={"panel-body"}  >

                                            <div style={{ height: d.height }} dangerouslySetInnerHTML={{ __html: d.content }} />

                                          </Panel.Body>
                                    </Panel>
                                </Col>
                            );
                        })}
                    </Row>
                </Grid>
            </div>);
    }

    render() {
        return (
            <div>
                <PageHeader>
                    Dashboard
                <br />
                </PageHeader>
                {(this.props.isRunLoading ? this.renderLoading : this.renderBoards)(this)}
            </div>
        );
    }
}

export default connect(
    state => state.dashboard,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Home);