import { actionCreators as userActions } from "../store/User";

const requestQueryRunType = "REQUEST_QUERY_RUN";
const receiveQueryRunType = "RECEIVE_QUERY_RUN";
const queryViewDataChange = "QUERY_VIEWDATA_CHANGE";
const errorQueryRunType = "ERROR_QUERY_RUN";
const clearErrorType = "CLEAR_ERROR";

const requestQueryListType = "REQUEST_QUERY_LIST";
const receiveQueryListType = "RECEIVE_QUERY_LIST";

const changeActiveQueryType = "CHANGE_ACTIVE_QUERY";
let controller = new AbortController();
let signal = controller.signal;
class QueryPageState {

    constructor(queryName, viewData) {
        this.queryName = queryName || "default";
        this.viewData = viewData || [];
        this.originalData = this.viewData;
        this.sortDirection = "NONE";
        this.sortColumn = "__order";
        this.filters = {};
        this.parameters = {};
        }
}

const initialState = {
    list: [], activeQueryName: null, activeQuery: null, resultDictionary: {}, queryResult: new QueryPageState(), isRunLoading: false, isListLoading: true, errorText: null
};

export const actionCreators = {

    clearError: () => (dispatch) => {
        dispatch({ type: clearErrorType });
    },


    changeViewData: (sortColumn, sortDirection, filter, clearFilter) => (dispatch, getState) => {
        const state = getState().query;
        const current = state.queryResult;
        let viewData;
        if (state.isRunLoading || state.isListLoading) {
            return;
        }

        const newFilters = clearFilter ? {} : { ...current.filters };
        if (filter) {
            console.log(filter);
            if (filter.filterTerm) {
                newFilters[filter.column.key] = filter;
            } else {
                delete newFilters[filter.column.key];
            }
        }

        viewData = current.originalData.slice(0).filter(r => {
            for (let columnName in newFilters)
                {
                    if (newFilters.hasOwnProperty(columnName))
                        {
                            if (r[columnName].toString().indexOf(newFilters[columnName].filterTerm) < 0)
                                {
                                    return false;
                                }
                        }
                }
            return true;
        });

        sortColumn = sortColumn || current.sortColumn;
        sortDirection = sortDirection || current.sortDirection;

        if (sortDirection === "NONE" || !sortColumn) {
            sortDirection = "ASC";
            sortColumn = "__order";
        }

        const comparer = (left, right) => {
            if (sortDirection === "ASC") {
                return left[sortColumn] > right[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return left[sortColumn] < right[sortColumn] ? 1 : -1;
            }
        };
        viewData = viewData.sort(comparer);

        dispatch({ type: queryViewDataChange, viewData, sortColumn, sortDirection, filters: newFilters });

    },
    clearQueryFilter: () => (dispatch, getState) => {
        actionCreators.changeViewData(null, null, null, true)(dispatch, getState);

    },
    queryFilter: (filter) => (dispatch, getState) => {
        actionCreators.changeViewData(null, null, filter)(dispatch, getState);
    },
    reorderQuery: (sortColumn, sortDirection) => (dispatch, getState) => {
        actionCreators.changeViewData(sortColumn, sortDirection, null)(dispatch, getState);
    },

    changeActiveQuery: (queryName) => (dispatch, getState) => {
        const state = getState().query;
        if (queryName === state.activeQueryName) {
            return;
        }
        if (state.isRunLoading ) {
            controller.abort();
        }
    
        let activeQuery = {};
        if (state.list.length < 1) {

            return;
        }
        activeQuery = state
            .list
            .filter(q => q.queryName === queryName)[0];

        const queryResult = state.resultDictionary[queryName] || new QueryPageState(queryName);
        dispatch({ type: changeActiveQueryType, queryName, activeQuery, queryResult });
    },

    requestQueryRun: (queryName, parameters) => async (dispatch, getState) => {

        controller.abort();
        controller = new AbortController();
        signal = controller.signal;
        dispatch({ type: requestQueryRunType });
        const userStore = getState().user;

        const url = `api/query/run/` + queryName + "?" + Object.getOwnPropertyNames(parameters).map(p => encodeURI(p) + "=" + encodeURI(parameters[p])).join("&");

        fetch(url, {
            method: "get",
            headers: {
                "Accept": "application/json",
                "token": userStore.token
            },
            signal: signal
        }).then(response => {

            if (response.status === 401) {
                dispatch(userActions.clearToken());
                return;
            }
            if (response.status === 200) {
                response.json().then((data) => {
                    const queryResult = new QueryPageState(queryName, data.map((x, i) => { return { ...x, __order: i }; }));
                    dispatch({ type: receiveQueryRunType, queryResult: queryResult });
                });
            }

            if (response.status === 500) {
                dispatch({ type: errorQueryRunType, errorText: "Hatalı Sorgu" });
                return;
            }

            }).catch((e) => {
                
                dispatch({ type: errorQueryRunType, errorText: controller.signal ? "Sorgu iptal edildi." : e });
            });
    },

    requestQueryList: () => async (dispatch, getState) => {
        const userStore = getState().user;
        const url = `api/query/list`;

        const requestOptions = {
            method: "get",
            headers: {
                "Accept": "application/json",
                "token": userStore.token
            }
        };

        fetch(url,requestOptions).then(response => {

            if (response.status === 401) {
                dispatch(userActions.clearToken());
                return;
            }
            if (response.status === 200) {
                response.json().then((queries) => {
                    dispatch({ type: receiveQueryListType, queries });
                });
            }
        }).catch((e) => {});



    }

};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === clearErrorType) {
        return {
            ...state,
            errorText: null
        };
    }

    if (action.type === changeActiveQueryType) {

        let old = {};
        old[state.activeQueryName] = state.queryResult;

        return {
            ...state,
            resultDictionary: { ...state.resultDictionary, ...old },
            activeQueryName: action.queryName,
            activeQuery: action.activeQuery,
            queryResult: action.queryResult

        };
    }


    if (action.type === requestQueryRunType) {
        return {
            ...state,
            isRunLoading: true,
            queryResult: new QueryPageState(state.queryName),
            errorText: null
        };
    }

    if (action.type === receiveQueryRunType) {
        return {
            ...state,
            parameters: action.parameters,
            queryResult: action.queryResult,
            isRunLoading: false,
            errorText: null

        };
    }

    if (action.type === queryViewDataChange) {
        return {
            ...state,
            queryResult: {
                ...state.queryResult,
                viewData: action.viewData,
                sortDirection: action.sortDirection,
                sortColumn: action.sortColumn,
                filters: action.filters
            }
        };
    }


    if (action.type === requestQueryListType) {
        return {
            ...state,
            isListLoading: true
        };
    }

    if (action.type === receiveQueryListType) {
        return {
            ...state,
            parameters: action.parameters,
            list: action.queries,
            isListLoading: false,
            errorText: null
        };
    }


    if (action.type === errorQueryRunType) {
        return {
            ...state,
            parameters: action.parameters,
            errorText: action.errorText,
            isRunLoading: false
        };
    }



    return state;
};
