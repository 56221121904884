import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/Query";
import { Button, FormGroup, Form, Badge, Panel, PageHeader, ControlLabel, FormControl, HelpBlock } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDataGrid from "react-data-grid";
import { Toolbar } from "react-data-grid-addons";
import MallSelector from "../../usercontrols/mallselector/MallSelector";
import DateSelector from "../../usercontrols/dateselector/DateSelector";
import CampaignSelector from "../../usercontrols/campaignselector/CampaignSelector";
import CountrySelector from '../../usercontrols/countryselector/CountrySelector';
import MemberFilterSelector from '../../usercontrols/memberfilterselector/MemberFilterSelector';
import XLSX from "xlsx";

//http://adazzle.github.io/react-data-grid/#/
class Query extends Component {

    constructor(props) {
        super(props);
        const requestedQueryName = this.props.match.params.queryName;
        this.props.changeActiveQuery(requestedQueryName);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
    }
    componentDidUpdate() {


        const requestedQueryName = this.props.match.params.queryName;
        this.props.changeActiveQuery(requestedQueryName);

        if (this.props.errorText) {
            toast.error("🦄 Hata oluştu! \r\n" + this.props.errorText, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            this.props.clearError();
        }

    }

    scroll() {
        setTimeout(() => {
            const endElement = document.getElementsByClassName("d-query-end")[0];
            if (endElement) {
                endElement.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        }, 40);

    }
    getValidationState(e) {
        return null;
    }

    handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        const obj = {};
        for (let key of data.keys()) {
            obj[key] = data.get(key);
        }
        this.props.requestQueryRun(this.props.activeQueryName, obj);
    }

    rowGetter = (i) => {

        return this.props.queryResult.viewData[i];
    }

    handleGridSort = (sortColumn, sortDirection) => {
        this.props.reorderQuery(sortColumn, sortDirection);
    };

    handleFilterChange = (filter) => {
        this.props.queryFilter(filter);
    };

    onClearFilters = () => {
        this.props.clearQueryFilter();
    };

    renderInput = (p) => {
        switch (p.typeName) {
            case "Mall":
                return <MallSelector name={p.parameterName} />;
            case "Campaign":
                return <CampaignSelector name={p.parameterName} />;
            case "Country":
                return <CountrySelector name={p.parameterName} />;
            case "System.String":
                return <FormControl type="text" name={p.parameterName} />;
            case "System.Int32":
                return <FormControl type="number" name={p.parameterName} />;
            case "System.DateTime":
                return <DateSelector type="number" name={p.parameterName} />;
            case "System.Boolean":
                return (
                    <FormControl componentClass="select" name={p.parameterName} placeholder="Seçin">
                        <option value="true">Evet / Aktif</option>
                        <option value="false">Hayır / Pasif</option>
                    </FormControl>
                );
            case "MemberFilter": return <MemberFilterSelector name={p.parameterName} />;
            default:
                return <FormControl type="text" name={p.parameterName} />;
        }
    }

    onHeaderDrop() { }

    exportToExcel(e) {
        e.preventDefault();

        const ws = XLSX.utils.json_to_sheet(this.props.queryResult.viewData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.props.queryName);

        //   var cell_ref = XLSX.utils.encode_cell({ s: { c: 0, r: 0 }, e: { c: 5, r: 0 } });
        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, this.props.activeQuery.queryName + ".xlsx");
    }

    render() {

        if (this.props.isListLoading) {
            return <span>Yükleniyor...</span>;
        }
        if (!this.props.activeQuery) {
            return <span>Geçersiz Query Bilgisi</span>;
        }


        const self = this;
        self.getTitle = new Date().toString();


        return (
            <div>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                    />
                </div>

                <PageHeader>
                    {this.props.activeQuery.displayName}
                    <br />
                    <small> {this.props.activeQuery.queryDescription}</small>
                </PageHeader>
                <Panel>
                    <Panel.Body>
                        <Form onSubmit={this.handleSubmit}>
                            {
                                this.props
                                    .activeQuery
                                    .parameters
                                    .map(p => {
                                        return (
                                            <FormGroup
                                                key={this.props.activeQueryName + "-" + p.parameterName}
                                                controlId={this.props.activeQueryName + "-" + p.parameterName}
                                                validationState={this.getValidationState()}
                                            >
                                                <ControlLabel>{p.displayName}</ControlLabel>
                                                {this.renderInput(p)}
                                                <FormControl.Feedback />
                                                <HelpBlock>{p.description}</HelpBlock>
                                            </FormGroup>
                                        );
                                    })
                            }
                            <FormGroup>
                                <Button bsStyle="primary" type="submit" disabled={this.props.isRunLoading} > {this.props.isRunLoading ? "Çalışıyor..." : "Çalıştır"}</Button>
                                <Button bsStyle="info" style={{ marginLeft: 10 }} onClick={this.exportToExcel} disabled={this.props.isRunLoading || (this.props.queryResult.viewData.length < 1)} > Excel</Button>
                            </FormGroup>
                        </Form>
                    </Panel.Body>
                </Panel>
                <div className={"d-query-end"}>{this.scroll()}</div>
                <h2>Sonuçlar <Badge>{this.props.queryResult.viewData.length} - {this.props.queryResult.originalData.length}</Badge></h2>

                {
                    this.props.queryResult.originalData && this.props.queryResult.originalData.length > 0 ?
                        <ReactDataGrid
                            columns={Object
                                .getOwnPropertyNames(this.props.queryResult.originalData[0])
                                .filter(columnName => columnName !== "__order")
                                .map((columnName, index) => ({ 'key': columnName, 'name': columnName, 'resizable': true, 'sortable': true, 'filterable': true }))}
                            rowGetter={this.rowGetter}
                            onGridSort={this.handleGridSort}
                            cellSelection={false}
                            rowSelection={false}
                            rowsCount={this.props.queryResult.viewData.length}
                            minHeight={510}
                            filterRowsButtonText="Süz"
                            toolbar={<Toolbar enableFilter />}
                            onAddFilter={this.handleFilterChange}
                            onClearFilters={this.onClearFilters}
                            sortColumn={this.props.queryResult.sortColumn}
                            sortDirection={this.props.queryResult.sortDirection}
                        />
                        : <Panel>
                            <Panel.Body>
                                {this.props.isRunLoading ? <span>Yükleniyor</span> :
                                    <span>Veri Yok</span>}
                            </Panel.Body>
                        </Panel>
                }
            </div>

        );
    }
}


export default connect(
    state => state.query,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Query);
