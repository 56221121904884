import React from "react";
import { Link } from "react-router-dom";
import { Glyphicon, Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./NavMenu.css";
import QueryNavigation from "./QueryNavigation";

export default props => (
    <Navbar fixedTop inverse fluid collapseOnSelect>
        <Navbar.Header>
            <Navbar.Brand>
                <Link to={"/"}>Minos</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
            <Nav>
                <LinkContainer to={"/"} exact>
                    <NavItem>
                        <Glyphicon glyph="home" />Dashboard
                    </NavItem>
                </LinkContainer>
                <LinkContainer to={"/clients"} exact>
                    <NavItem>
                        <Glyphicon glyph="home" />İstemciler
                    </NavItem>
                </LinkContainer>
                <QueryNavigation />
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);
