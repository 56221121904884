import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import NavMenu from "./NavMenu";


export default props => (
    <Grid fluid>
        <Row>
            <Col sm={3} lg={2}>
                <NavMenu />
            </Col>
            <Col sm={9} lg={10}>
                {props.children}
            </Col>
        </Row>
    </Grid>
);
