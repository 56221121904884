import React, { Component } from "react";
import Select, { components } from "react-select";
import { store } from "../../../index";
import {
    Alert,
    Button,
    Col,
    Row,
    FormControl,
    ControlLabel
} from "react-bootstrap";

import MallSelector from '../mallselector/MallSelector';

export default class MemberFilterSelector extends Component {
    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        this.reload = this.reload.bind(this);
        this.renderError = this.renderError.bind(this);
        this.renderNormal = this.renderNormal.bind(this);
        this.onMallCodeChanged = this.onMallCodeChanged.bind(this);
        this.getProjectName = this.getProjectName.bind(this);
    }

    state = {
        mallCode: null,
        selectedOption: null,
        isLoading: true,
        list: [],
        errorText: null,
        options: [],
        projectName: null
    };

    reload() {
        window.location.reload(true);
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
    };

    getList() {
        if (!this.state.mallCode) {
            return;
        }

        const { mallCode } = this.state;
        this.setState({ isLoading: true, list: [], selectedOption: null });
        const url = `api/options/memberfilter/?mallcode=` + mallCode;
        fetch(url, {
            method: "get",
            headers: {
                Accept: "application/json",
                token: store.getState().user.token
            }
        })
            .then(response => {
                if (response.status === 200) {
                    response.json().then(result => {
                        this.setState({
                            isLoading: false,
                            list: result.map(m => ({ label: m.Name, value: JSON.parse(m.value).Guid })),
                            errorText: null
                        });
                    });
                    return;
                } else {
                    this.setState({
                        isLoading: false,
                        list: [],
                        errorText: "Hata! Liste alınamadı."
                    });
                }
            })
            .catch(e => {
                this.setState({
                    isLoading: false,
                    list: [],
                    errorText: "Hata! Liste alınamadı."
                });
            });
    }

    getProjectName(mallCode) {
        const url = `api/options/projectname/?mallcode=` + mallCode;
        fetch(url, {
            method: "get",
            headers: {
                Accept: "application/json",
                token: store.getState().user.token
            }
        }).then(response => {
            if (response.status === 200) {
                response.json().then(result => {
                    if (result[0]) {
                        this.setState({
                            projectName: result[0].ProjectName
                        });
                    }
                });
            }
        }).catch(err => { });
    }

    onMallCodeChanged(value) {
        this.getProjectName(value.value);
        this.setState({ mallCode: value.value }, this.getList);
    }

    renderError() {
        return (
            <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
                <h4>Müşteri Kriter Listesi Çekilemedi!</h4>
                <p>
                    Listeyi çekmeyi tekrar deneyebilirsin veya sayfayı komple yeniden
                    yükleyebilirsin.
        </p>
                <p>
                    <Button onClick={this.getList} bsStyle="danger">
                        Yeniden Dene
          </Button>
                    <span> veya </span>
                    <Button onClick={this.reload}>Baştan yükle</Button>
                </p>
            </Alert>
        );
    }

    renderNormal() {
        const Option = props => {
            return (
                <div>
                    <components.Option {...props} />
                </div>
            );
        };

        const customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "white" : provided.color,
                backgroundColor: state.isSelected ? "#815789" : provided.color
            }),
            singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = "opacity 300ms";

                return { ...provided, opacity, transition };
            }
        };

        const { selectedOption, list, isLoading } = this.state;
        return (
            <div>
                <Row >
                    <Col xs={12} md={6}>
                        <MallSelector onValueChanged={this.onMallCodeChanged} name={""} />
                    </Col>
                    <Col xs={12} md={6}>
                        <Select
                            styles={customStyles}
                            value={selectedOption}
                            components={{ Option }}
                            options={list}
                            onChange={this.handleChange}
                            isLoading={isLoading}
                            isSearchable
                            placeholder={"Müşteri Kriter Seçiniz"}
                        />

                        <input type={"hidden"} name={this.props.name} value={selectedOption ? selectedOption.value : ""} />
                        <input type={"hidden"} name={"MallCode"} value={this.state.mallCode} />

                    </Col>

                </Row>
                <br />
                <ControlLabel>Proje Ad </ControlLabel>
                <FormControl value={this.state.projectName} name={"ProjectName"} />

            </div>
        );
    }

    render() {
        return (this.state.errorText ? this.renderError : this.renderNormal)();
    }
}
