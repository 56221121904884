import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../store/Clients";
import { connect } from "react-redux";
import ClientsDashboard from "./parts/clientsdashboard/ClientsDashboard";
import ClientList from "./parts/clientlist/ClientList";
import { Grid, Row, Col, PageHeader, Panel } from "react-bootstrap";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.interval = 0;

    }
    componentDidMount() {
        console.log("mount");
        this.interval = setInterval(this.props.requestRefreshData, 5000);
        if (!this.props.isRunLoading) {
            this.props.requestRefreshData();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderLoading() {
        return <span>Yükleniyor...</span>;
    }

    render() {
        return (
            <div>
                <PageHeader>
                    İstemciler
                <br />
                </PageHeader>
                <div>
                    <ClientsDashboard {...this.props} />
                    <ClientList {...this.props} />

                </div>
                <footer>
                    &nbsp;
                </footer>
            </div>
        );
    }
}

export default connect(
    state => state.clients,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Clients);